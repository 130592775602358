<template>
  <ledger-review :typePage="type" :fieldList="fieldList"></ledger-review>
</template>

<script>
import LedgerReview from "@/views/modules/collection/accounts/ledgerReview.vue";

export default {
    name: "accountExamine",
    components: {LedgerReview},
    data(){
        return{
            type:'accountExamine',
            fieldList:[
                {
                    "archivesBasicTypeMethodCode":"=",
                    "key":"entryState",
                    "theKey":"",
                    "value":"2"
                },
                {
                    "archivesBasicTypeMethodCode":"=",
                    "key":"processState",
                    "theKey":"",
                    "value":"1"
                },
            ],
        }
    },
}
</script>

<style scoped>

</style>